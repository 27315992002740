import React from "react";
import Hero2 from "../components/Hero2";
import Sections from "../components/Sections";
import Logo from "../assets/images/herologo1.PNG";
import sectionImg1 from "../assets/images/img888.jpg";
import sectionImg2 from "../assets/images/img11.jpg";

function Firstteam() {
  return (
    <>
     <Hero2
   logoImg={Logo}
   heading={"First Team"}/>
       <Sections
        className="section-group-container"
        imgClassName="section-img"
        textClassName="section-text"
        sectionHeader="The Team"
        sectionSubheader="Our first team are the pathway for our academy. The last two seasons our first team have won the league and league cup. "
        sectionSubheader2="Our success is built upon a dedication to promote the enjoyment and love of playing football as well as developing young players that are dedicated to following a football player pathway. In doing so, the club will always aim to be a positive influence on players who aspire to represent Future City."
        sectionImg1={sectionImg1}
      />
      <Sections
        className="section-group-container-reverse"
        imgClassName="section-img-reverse"
        textClassName="section-text-reverse"
        sectionHeader="Growth"
        sectionSubheader="Our aim will always be to help players grow as creative and healthy individuals so they can make their debut for the first team."
        sectionSubheader2=""
        sectionImg1={sectionImg2}
      />
  </>
  );
}

export default Firstteam;