import { motion, useInView, useAnimation } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";
import "./Hero.css";
import heroImg from '../assets/images/web-dev.svg';

export default function Hero(props) {
  const handleClick = () => {
    let baseScrollDistance = 900; // Base distance that works for a standard layout
    let dynamicAdjustment = 0;
  
    // Adjust the scroll distance based on the viewport width
    if (window.innerWidth < 600) { // For smaller devices like mobile phones
      dynamicAdjustment = 20; // Reduce the scroll distance
    } else if (window.innerWidth < 640) { // For larger devices like desktops
      dynamicAdjustment = 120; // Increase the scroll distance
    }
     else if (window.innerWidth < 768) { // For larger devices like desktops
      dynamicAdjustment = 50; // Increase the scroll distance
    }
     else if (window.innerWidth < 800) { // For larger devices like desktops
      dynamicAdjustment = -400; // Increase the scroll distance
    }
     else if (window.innerWidth < 940) { // For larger devices like desktops
      dynamicAdjustment = -350; // Increase the scroll distance
    }
     else if (window.innerWidth > 940) { // For larger devices like desktops
      dynamicAdjustment = -300; // Increase the scroll distance
    }
  
    const finalScrollDistance = baseScrollDistance + dynamicAdjustment;
  
    window.scrollBy({
      top: finalScrollDistance, 
      behavior: 'smooth'
    });
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div ref={ref} className="pt-[8rem] h-auto pb-[10rem] sm:px-6 bg-black hero-container">
 
 <div className="px-[5rem] md:w-1/2 md:h-1/2 pt-10 md:float-left md:text-left md:pl-8 lg:pl-20 text-center hero-text-container">  
 <motion.div className="text-white hero-text-container"
    variants={{
      hidden: { opacity: 0, y: 60 },
      visible: {
        opacity: 1,
        y: 0,
      },
    }}
    initial="hidden"
    animate={mainControls}
    transition={{
      type: "tween",
      duration: 1,
      ease: "easeOut",
      delay: 0.1,
    }}
  >
    <h1 className="text-6xl font-bold hero-heading">TORJO</h1>
    <h2 class="mt-5 text-4xl font-bold hero-heading flex flex-col sm:flex-row md:justify-start justify-center items-center text-center z-10">
  <span class="gradient-text">WEBSITES</span>

  <span class="separator-line hidden sm:block mx-2"></span>
  <span class="gradient-text">APPS</span>

  <span class="separator-line hidden sm:block mx-2"></span>
  <span class="gradient-text">DESIGN</span>
</h2>






<p className="sm:px-6 md:px-0  text-xl hero-subheading mt-4">
  We are Torjo. A product agency, building mobile and web apps for diverse audiences and needs.
</p>
    <button className="mt-8 px-6 py-2 rounded bg-blue-500 text-white hover:bg-blue-700 transition duration-300 ease-in-out"
    onClick={handleClick}>LEARN MORE</button>
  </motion.div>
</div>

<div className="svg-container mt-8 md:mt-0">
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 60 },
            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            type: "tween", // Use "tween" for linear interpolation
            duration: 1, // Adjust the duration for the desired slow-down effect
            ease: "easeOut", // Choose an easing function for smooth slow-down
            delay: 0.5,
          }}
        >
<img 
  alt="card img" 
  className="rounded-t mx-auto w-full md:w-1/2 lg:max-w-[700px] duration-1000" 
  src={heroImg} 
/>
                             </motion.div>
      </div>
    </div>
  );
}
