import React from "react";

export default function ProjectsDisplay({
  title = "",
  subtitle = "",
  services = [],
}) {
  return (
    <div id="services" className="section relative pt-20 pb-8 md:pt-16 md:pb-0">
      <div className="container py-[6vw] xl:max-w-6xl mx-auto px-4">
        <header className="text-center mx-auto mb-10 lg:px-20">
          <h2 className="text-3xl leading-normal mb-2 font-bold text-black">
            {title}
          </h2>
          <svg
            viewBox="0 0 100 60"
            style={{ margin: "0 auto", height: "35px" }}
          >
            {/* SVG content here */}
          </svg>
          <p className="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">
            {subtitle}
          </p>
        </header>

        <div className="flex flex-wrap flex-row -mx-4 text-center">
          {services.map((service) => (
            <div
              key={service.title}
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2  lg:px-6"
            >
              <div className="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                {service.imageUrl && (
                  <img
                    src={service.imageUrl}
                    alt={service.title}
                    className="mb-4 mx-auto w-full h-auto max-h-[200px] object-cover rounded"
                  />
                )}
                <h3 className="text-lg leading-normal mb-2 font-semibold text-black">
                  {service.title}
                </h3>
                <p className="text-gray-500">{service.description}</p>
                {service.url && (
                  <a
                    href={service.url}
                    className="mt-4 inline-block px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    View Project
                  </a>
                )}
                {!service.url && (
                  <a
                    href={service.url}
                    className="mt-4 inline-block px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-300 cursor-not-allowed"
                    onClick={(e) => e.preventDefault()}
                  >
                    Coming Soon...
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
