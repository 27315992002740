import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./Features.css";

export default function Features(props) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <>
      <div className={`bg-gradient-to-r from-blue-500 to-indigo-700 px-[10vw] py-10 flex flex-col md:flex-row justify-center items-center text-white ${props.flexStyle}`}>
        <div ref={ref} className="features-img w-full md:w-1/2 lg:w-2/5 mx-auto">
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <img className="w-full md:w-[50vw] lg:w-[50vw]" src={props.sectionImg1} alt="" />
          </motion.div>
        </div>
        <div className="features-text-container py-10 text-left mt-4 md:mt-0 md:w-1/2 lg:w-1/2">
          <h3 className="mb-4 font-black text-3xl">{props.sectionHeader}</h3>
          <p className="text-md font-thin">{props.sectionSubheader}</p>
        </div>
      </div>
    </>
  );
}
