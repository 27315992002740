import React from "react";
import Hero2 from "../components/Hero2";
import Sections from "../components/Sections";
import Logo from "../assets/images/herologo1.PNG";
import sectionImg1 from "../assets/images/img9.jpg";
import sectionImg2 from "../assets/images/img4.jpg";
import sectionImg3 from "../assets/images/img3.jpg";

function One2one() {
  return (
    <>
     <Hero2
   logoImg={Logo}
   heading={"One 2 one "}/>
       <Sections
        className="section-group-container"
        imgClassName="section-img"
        textClassName="section-text"
        sectionHeader="1-2-1 and Small Group player development "
        sectionSubheader="We support players on a One to One & Small Group level to maximise the development outcomes."
        sectionSubheader2="Always listen to the players to co-create a tailored personal program to build on their technical, physical and game understanding attributes. "
        sectionImg1={sectionImg1}
      />
      <Sections
        className="section-group-container-reverse"
        imgClassName="section-img-reverse"
        textClassName="section-text-reverse"
        sectionHeader="Everyone is welcome"
        sectionSubheader="For boys & girls aged 5 - 18 years
        Delivered by Future City qualified & expert coaches."
        sectionSubheader2="We're all about getting to know each player personally, whether it's one-on-one or in small groups. Our aim? To help every player reach their absolute best."
        sectionImg1={sectionImg2}
      />
        <Sections
        className="section-group-container"
        imgClassName="section-img"
        textClassName="section-text"
        sectionHeader="True potential"
        sectionSubheader="On our field, it's not just about drills; it's about thrilling dribbles, epic saves, and a whole lot of good times."
        sectionSubheader2="Elevate your game while keeping your smile game strong. We're here to turn sweat into sweet victories and high-fives!"
        
        sectionImg1={sectionImg3}
      />
  </>
  );
}

export default One2one;