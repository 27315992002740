import React from "react";
import Hero2 from "../components/Hero2";
import ProjectsDisplay from "../components/ProjectsDisplay";
import Logo from "../assets/images/herologo1.PNG";
import prayerWidgetsImg from "../assets/images/prayerwidgets.png"; // Assuming you have an image for this
import vistoImg from "../assets/images/vistoImg.png"; // Assuming you have an image for Torjomana

function Projects() {
  return (
    <>
      <Hero2 logoImg={Logo} />

      <ProjectsDisplay
        title="Our Featured Projects"
        subtitle="Explore our diverse range of projects."
        services={[
          {
            title: "Prayer Widgets",
            description:
              "Innovative widgets for iPhone homescreens displaying prayer times extracted from different mosques. These widgets offer a seamless integration with the user's daily routine, ensuring they never miss a prayer.",
            imageUrl: prayerWidgetsImg,
            url: "http://prayerwidgets.vercel.app", // Replace with the actual URL
          },
          {
            title: "Visto Docs",
            description:
              "Visto Docs makes it easy for non-English speakers: take a photo of any document, and it provides a contextual summary and translation in your chosen language, capturing cultural nuances.",
            imageUrl: vistoImg,
          },
          // ... more services
        ]}
      />
    </>
  );
}

export default Projects;
