import React, { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { BlurhashCanvas } from 'react-blurhash';
import './Sections.css';
export default function Sections(props) {

  const [imageLoaded, setImageLoaded] = useState(false);

  const ref = useRef(null);
const isInView = useInView(ref, { once: true});
const mainControls = useAnimation();

useEffect(() => {
 if (isInView) {
  mainControls.start("visible");
 }
}, [isInView]);
  return (
    <>
 
        <div className={props.className}>
      <div ref={ref} className={`${props.textClassName} hover:scale-105 transition-transform duration-300 ease-in-out`}>
      <motion.div 
            variants={{ 
              hidden: { opacity: 0, y: 20 },
visible: { opacity: 1, y: 0 },
            }}
initial="hidden"
animate={mainControls}
transition={{ duration: 0.5, delay: 0.25 }}
>
        <h2 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">{props.sectionHeader}</h2>
        <p className='mb-1 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400'>{props.sectionSubheader}</p>
        <p className='mb-1 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400'>{props.sectionSubheader2}</p>
        </motion.div>
      </div>
      <div className={`${props.imgClassName} hover:scale-105 transition-transform duration-300 ease-in-out`}>
      <div style={{height: '23rem', width: '50rem', display: !imageLoaded ? 'inline' : 'none'}}>
        <BlurhashCanvas hash='LRI#fF~pxWNX^,?HxtayInnQozs=' />
        </div>
        <img onLoad={() => {setImageLoaded(true)}} style={{display: imageLoaded ? 'inline' : 'none'}} src={props.sectionImg1} alt="" />
   
      </div>
      </div>
    </>
  )
}
