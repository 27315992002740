import React from "react";
import ContactFooter from "../components/ContactFooter";
function Contact() {

  return (
    <>
    <ContactFooter/>



  </>
  );
}

export default Contact;