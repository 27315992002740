import React from "react";
import Hero2 from "../components/Hero2";
import Sections from "../components/Sections";
import Logo from "../assets/images/herologo1.PNG";
import sectionImg1 from "../assets/images/img10.jpg";
import sectionImg2 from "../assets/images/img12.jpg";

function Preacademy() {
  return (
    <>
      <Hero2 logoImg={Logo} heading={"Pre Academy"} />
      <Sections
        className="section-group-container"
        imgClassName="section-img"
        textClassName="section-text"
        sectionHeader="Experience"
        sectionSubheader="Our Pre-Academy programme continues to thrive with boys and girls encouraged to fall in love with football and enjoy an authentic childhood experience. "
        sectionSubheader2="There have been some amazing success stories from this phase of the Academy structure. A number of our players have been selected to trial at Manchester United, Liverpool, Manchester City and more. "
        sectionImg1={sectionImg1}
      />
      <Sections
        className="section-group-container-reverse"
        imgClassName="section-img-reverse"
        textClassName="section-text-reverse"
        sectionHeader="Talent"
        sectionSubheader="From hometown heroes to global stages, our program lays the foundation for aspiring footballers to chase their dreams and script their own success stories."
        sectionSubheader2="Our success stories are proof that dreams don't just come true; they flourish through dedication, training, and a community that believes in each player's potential."
        sectionImg1={sectionImg2}
      />
    </>
  );
}

export default Preacademy;
