import React from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Services from "../components/Services";
import Logo from "../assets/images/herologo1.PNG";
import sectionImg1 from "../assets/images/web-dev.svg";
import sectionImg2 from "../assets/images/websites-icon.svg";
import sectionImg3 from "../assets/images/apps-icon.svg";
import sectionImg4 from "../assets/images/web-search-icon.svg";


function Home() {
  return (
    <>
      <Hero logoImg={Logo} />
      <Features
        sectionHeader="Startup Catalyst"
        sectionSubheader="Torjo is your launchpad. We empower startups to transform ideas into functional digital products. With our expertise, navigate the early stages of development smoothly, turning your vision into a market-ready reality."
         sectionImg1={sectionImg2}
         flexStyle={'md:flex-row'}
      />
      <Features
        sectionHeader="Tech Simplified for All"
        sectionSubheader="No tech background? No problem. At Torjo, we bridge the gap between complex technology and your business needs. Our team handles the technicalities, delivering user-friendly digital solutions that align with your objectives, without requiring you to master the tech jargon."
         sectionImg1={sectionImg3}
         flexStyle={'md:flex-row-reverse'}

      />
      <Features
        sectionHeader="Diverse Solutions for a Diverse World"
        sectionSubheader="We thrive on creating for a diverse audience. Our focus is on designing accessible and inclusive digital products that cater to varied needs and preferences. Torjo is committed to ensuring that technology is a tool for everyone, enhancing user engagement across different demographics."
         sectionImg1={sectionImg4}
         flexStyle={'md:flex-row'}

      />
<Services
  title="What We Do"
  subtitle="Save time managing advertising & Content for your business."
  services={[
    {
      title: "Web Development",
      description: "Building custom websites that are easy to use and help your business stand out."
    },
    {
      title: "App Development",
      description: "Making mobile apps that are user-friendly and fit your needs."
    },
    {
      title: "Go-to-Market Consulting",
      description: "Providing clear, actionable advice for launching your digital products effectively."
    },

  ]}
/>

    </>
  );
}

export default Home;
