

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text",
  },
  {
    title: "Projects",
    path: "/projects",
    cName: "nav-text",
  },
];
