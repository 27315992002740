import { motion, useInView, useAnimation } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";
import "./Hero.css";
import heroImg from '../assets/images/projects-hero.svg';

export default function Hero2(props) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div ref={ref} className="pt-[8rem] h-auto pb-[10rem] sm:px-6 bg-black hero-container">
      {/* Text Section */}
      <div className="px-[5rem] md:w-1/2 md:h-1/2 pt-10 md:float-left md:text-left md:pl-8 lg:pl-20 text-center hero-text-container">
        <motion.div className="text-white"
          // ... (existing motion div properties)
        >
          <h1 className="text-6xl font-bold hero-heading">Projects</h1>
          <h2 className="mt-5 text-4xl font-bold flex flex-col sm:flex-row md:justify-start justify-center items-center text-center z-10">
            <span className="gradient-text">Innovative</span>
            <span className="separator-line hidden sm:block mx-2"></span>
            <span className="gradient-text">Creative</span>
            <span className="separator-line hidden sm:block mx-2"></span>
            <span className="gradient-text">Impactful</span>
          </h2>
          <p className="md:px-0 text-xl mt-4 hero-subheading">
            Discover our portfolio of web, app, and design projects.
          </p>
      
        </motion.div>
      </div>

      {/* Image Section */}
      <div className="svg-container mt-8 md:mt-0">
        <motion.div
          // ... (existing motion div properties)
        >
          <img alt="Showcase of our projects" className="pt-14 rounded-t mx-auto w-4/5 md:w-1/2 max-w-[500px]" src={heroImg} />
        </motion.div>
      </div>
    </div>
  );
}