import React from "react";

export default function ContactFooter() {
  return (
    <div>
      <section className="py-40 bg-gray-100" data-scroll-index="4">
        <div className="container mx-auto px-4 md:px-10 lg:px-20">
          {" "}
          {/* Increased horizontal padding for larger screens */}
          <div className="text-center">
            <h3 className="text-3xl font-bold">Contact us</h3>
            <div className="mx-auto h-1 w-16 bg-indigo-500 mt-2 mb-5"></div>
            <p className="text-lg text-gray-600">
              Let us know if you have any questions
            </p>
          </div>
          <div className="mt-10">
            <div className="flex justify-center">
              <div className="bg-gradient-to-r from-blue-500 to-indigo-700 w-full md:w-3/5 lg:w-2/4 p-6 rounded-lg shadow-md">
                {" "}
                {/* Adjusted width and increased border-radius */}
                {/* Email */}
                <div className="flex items-center text-white justify-around">
                  <div className="flex items-start">
                    <i className="fa fa-envelope mr-2 mt-1"></i>
                    <div className="flex">
                      <p className="text-md uppercase font-semibold"></p>
                      <a href="mailto:support@torjo.co.uk">
                        support@torjo.co.uk
                      </a>
                    </div>
                  </div>
                  {/* Telephone */}
                  {/* <div className="flex items-start">
                    <i className="fa fa-phone mr-2 mt-1"></i>
                    <div>
                      <p className="text-md uppercase font-semibold">Telephone</p>
                      <a  href="tel:07777777777">
                        07777777777
                      </a>
                    </div>
                  </div> */}
                </div>
                {/* Other contact items can go here */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
