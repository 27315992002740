import React, { useRef } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Contact from "./routes/Contact";
import Footer from "./routes/Footer";
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import Preacademy from "./routes/Preacademy";
import Projects from "./routes/Projects";
import Firstteam from "./routes/Firstteam";
import Community from "./routes/Community";
import One2one from "./routes/One2one";
import Showcase from "./routes/Showcase";

import "./App.css";

const AppLayout = () => {
  // Create a ref for the Contact component
  const contactRef = useRef();

  // Function to handle the "Contact us" button click and scroll to the Contact component
  const handleContactClick = () => {
    scrollToContact();
  };

  // Function to scroll to the Contact component
  const scrollToContact = () => {
    if (contactRef.current) {
      const contactTop = contactRef.current.offsetTop;
      const scrollY = window.scrollY;
  
      const distanceToContact = contactTop - scrollY;
      const scrollDuration = 300; // Adjust the scroll duration as needed
  
      const startTime = performance.now();
  
      const scrollStep = (timestamp) => {
        const currentTime = timestamp - startTime;
        const progress = Math.min(currentTime / scrollDuration, 1);
        const scrollTo = scrollY + distanceToContact * progress;
  
        window.scrollTo(0, scrollTo);
  
        if (currentTime < scrollDuration) {
          requestAnimationFrame(scrollStep);
        }
      };
  
      requestAnimationFrame(scrollStep);
    }
  };
  
  
  return (
    <>
      <Navbar handleContactClick={handleContactClick} />
      <Outlet /> 
       <div ref={contactRef}>
      <Contact /> 
      <Footer/>
    
     </div>
     <ScrollToTop /> 
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "Preacademy",
        element: <Preacademy />,
      },
      {
        path: "Projects",
        element: <Projects />,
      },
      {
        path: "Firstteam",
        element: <Firstteam />,
      },
      {
        path: "community",
        element: <Community />,
      },
      {
        path: "One2one",
        element: <One2one />,
      },
      {
        path: "Showcase",
        element: <Showcase />,
      }
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
