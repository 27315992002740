import React from 'react';

export default function Services({ title = '', subtitle = '', services = [] }) {
  return (
    <div id="services" className="section relative pt-20 pb-8 md:pt-16 md:pb-0">
      <div className="container py-[10vw] xl:max-w-6xl mx-auto px-4">
        <header className="text-center mx-auto mb-12 lg:px-20">
          <h2 className="text-2xl leading-normal mb-2 font-bold text-black">{title}</h2>
          <svg viewBox="0 0 100 60" style={{ margin: '0 auto', height: '35px' }}>
            {/* SVG content here */}
          </svg>
          <p className="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">
            {subtitle}
          </p>
        </header>

        <div className="flex flex-wrap flex-row justify-center items-center -mx-4 text-center">
          {services.map(service => (
            <div key={service.title} className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6">
              <div className="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 mb-4">
                  {/* Icon for the service */}
                </div>
                <h3 className="text-lg leading-normal mb-2 font-semibold text-black">{service.title}</h3>
                <p className="text-gray-500">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
