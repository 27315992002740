import React from "react";
import Hero2 from "../components/Hero2";
import Sections from "../components/Sections";
import Logo from "../assets/images/herologo1.PNG";
import sectionImg1 from "../assets/images/img5.jpg";
import sectionImg2 from "../assets/images/img1.jpg";

function Showcase() {
  return (
    <>
    <Hero2
  logoImg={Logo}
  heading={"Academy Showcase"}/>
      <Sections
        className="section-group-container"
        imgClassName="section-img"
        textClassName="section-text"
        sectionHeader="Academy

        Showcase "
        sectionSubheader="Future City providing showcase opportunities to all our players to the famous Manchester United & Manchester City training ground"
        sectionSubheader2="There have been some amazing success stories from this phase of the Academy structure. A number of our players have been selected to trial at Manchester United, Liverpool, Manchester City and more. "
        sectionImg1={sectionImg1}
      />
      <Sections
        className="section-group-container-reverse"
        imgClassName="section-img-reverse"
        textClassName="section-text-reverse"
        sectionHeader="Academy

        Showcase "
        sectionSubheader="Our Pre-Academy programme continues to thrive with boys and girls encouraged to fall in love with football and enjoy an authentic childhood experience. "
        sectionSubheader2="There have been some amazing success stories from this phase of the Academy structure. A number of our players have been selected to trial at Manchester United, Liverpool, Manchester City and more. "
        sectionImg1={sectionImg2}
      />
 </>
  );
}

export default Showcase;