import React from "react";
import Hero2 from "../components/Hero2";
import Sections from "../components/Sections";
import Logo from "../assets/images/herologo1.PNG";
import sectionImg1 from "../assets/images/img7.jpg";
import sectionImg2 from "../assets/images/img6.jpg";


function Community() {
  return (
    <>
     <Hero2
   logoImg={Logo}
   heading={"Community"}/>
       <Sections
        className="section-group-container"
        imgClassName="section-img"
        textClassName="section-text"
        sectionHeader="Open access"
        sectionSubheader="Our community group based in South Manchester provide an open-access programme of technical and tactical football coaching for players of all abilities who have an interest in learning more about the game. "
        sectionSubheader2="Our community group are the entry point to our Academy Programme for players to develop in an enjoyable and relaxed learning environment."
        sectionImg1={sectionImg1}
      />
      <Sections
        className="section-group-container-reverse"
        imgClassName="section-img-reverse"
        textClassName="section-text-reverse"
        sectionHeader="Diversity"
        sectionSubheader="At our community group, we take pride in fostering a welcoming and inclusive atmosphere, where individuals from diverse backgrounds come together with a shared passion for football."
        sectionSubheader2="Through our open-access programme, we aim to empower players with the necessary technical skills and tactical knowledge to excel both on and off the pitch."
        sectionImg1={sectionImg2}
      />
  </>
  );
}

export default Community;